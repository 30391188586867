<template>
    <el-container>
        <MenuView currentPath="/convert/image" />
        <el-main class="right-panel">
            <el-card class="box-card" style="margin-bottom: 20px;">
                <h3>Image Format Conversion Tool</h3>
                <p>This tool allows you to convert images to different formats including PNG, JPG, WebP, and Base64. Follow these steps to use the tool:</p>
                <ol>
                    <li>Upload an image file (PNG, WEBP, JPG, or JPEG) by dragging it to the upload area or clicking to select a file.</li>
                    <li>Select the desired conversion option: PNG, JPG, WebP, or Base64.</li>
                    <li>Click the "Convert" button to start the conversion process.</li>
                    <li>Once the conversion is complete, you can download the converted file or view the Base64 encoded string if that option was selected.</li>
                </ol>
                
            </el-card>
            <el-upload
                ref="upload"
                class="upload-demo"
                action="#"
                drag
                :auto-upload="false"
                :limit="1"
                accept=".png,.jpg,.jpeg,.webp"
                :on-change="handleFileChange"
                :on-exceed="handleExceed"
                :before-upload="beforeUpload"
                :on-remove="handleRemove"
                :file-list="fileList"
            >
                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                <div class="el-upload__text">
                    Drop image here or <em>click to upload</em>
                </div>
                <template #tip>
                    <div class="el-upload__tip">
                        Currently, the supported image formats are PNG, WEBP, JPG, and JPEG, and the image size cannot exceed 10MB.
                    </div>
                </template>
            </el-upload>
            <el-form ref="form" :model="form" label-width="160px" class="form-container">
                <el-form-item label="Conversion Options">
                    <el-radio-group v-model="form.option">
                        <el-radio :label="'3'">To JPG</el-radio>
                        <el-radio :label="'4'">To PNG</el-radio>
                        <el-radio :label="'5'">To WebP</el-radio>
                        <el-radio :label="'6'">To Base64</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSubmit" :loading="loading">
                        Convert
                        <el-icon v-if="loading" class="loading-icon"><loading /></el-icon>
                    </el-button>
                </el-form-item>
            </el-form>
            <el-table :data="conversionRecords" v-if="conversionRecords.length > 0" class="conversion-table">
                <el-table-column prop="fileName" label="File Name" width="180"></el-table-column>
                <el-table-column prop="conversionType" label="Conversion Type" width="180">
                    <template #default="scope">
                        {{ conversionOptions[scope.row.conversionType] }}
                    </template>
                </el-table-column>
                <el-table-column prop="downloadUrl" label="Download Link">
                    <template #default="scope">
                        <a v-if="scope.row.downloadUrl" :href="scope.row.downloadUrl" target="_blank">Download</a>
                        <el-button v-else @click="showBase64(scope.row.base64String)">Show Base64</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="countdown" label="Expiration">
                    <template #default="scope">
                        {{ scope.row.countdown }}
                    </template>
                </el-table-column>
                <el-table-column label="Note">
                    <template #default>
                        File will be deleted after expiration.
                    </template>
                </el-table-column>
            </el-table>
            <el-divider></el-divider>
            <el-dialog
                title="Base64 Encoded String"
                v-model="base64DialogVisible"
                width="50%"
            >
                <el-input
                    type="textarea"
                    :rows="10"
                    :value="base64Content"
                    readonly
                />
                <template #footer>
                    <el-button @click="base64DialogVisible = false">Close</el-button>
                </template>
            </el-dialog>
            <div><strong>Version 1.0.1</strong> (2024-06-06)</div>
<p>Added support for converting from WebP to JPG, PNG, and Base64.</p>
            <div><strong>Version 1.0.0</strong> (2024-06-01)</div>
<p>Supported conversion from PNG and JPG to JPG, PNG, WebP, and Base64.</p>
            <FooterView />
        </el-main>
    </el-container>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import { genFileId } from 'element-plus';
import { useHead } from '@vueuse/head'
export default {
    setup() {
        useHead({
            title: 'Free online Convert Image',
            meta: [
                {
                    name: 'keywords',
                    content: 'png,jpg,webp,base64,convert image to png jpg webp base64.'
                },
                {
                    name: 'description',
                    content: 'online Convert image format - Convert a image file to png, jpg, webp, base64.'
                }
            ]
        });
    },
    data() {
        return {
            fileList: [],
            form: {
                option: '3'
            },
            conversionRecords: [],
            loading: false,
            upload: ref(null),
            base64DialogVisible: false,
            base64Content: '',
            conversionOptions: {
                '3': 'To JPG',
                '4': 'To PNG',
                '5': 'To WebP',
                '6': 'To Base64'
            }
        };
    },
    methods: {
        handleExceed(files) {
            console.log('Exceed files:', files);
            this.upload.clearFiles();
            const file = files[0];
            file.uid = genFileId();
            this.upload.handleStart(file);
        },
        handleFileChange(file, fileList) {
            this.fileList = fileList;
        },
        beforeUpload(file) {
            // Check file size before uploading
            if (file.size / 1024 / 1024 > 10) {
                this.$message.error('File size cannot exceed 10MB!');
                return false;
            }
            return true;
        },
        handleRemove(file, fileList) {
            this.fileList = fileList;
        },
        removeFile(file) {
            const index = this.fileList.indexOf(file);
            if (index > -1) {
                this.fileList.splice(index, 1);
            }
        },
        async handleSubmit() {
            if (this.fileList.length === 0) {
                this.$message.error('Please select a file!');
                return;
            }

            this.loading = true;

            const formData = new FormData();
            formData.append('file', this.fileList[0].raw);
            formData.append('ct', this.form.option);
            const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
            try {
                const response = await axios.post(apiBaseUrl + '/api/convert/image', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if (response.data.code === 0) {
                    this.$message.success('Image converted successfully!');
                    const downloadUrl = response.data.data.url;
                    const base64String = response.data.data.base64;
                    const expireTimeInSeconds = response.data.data.expire_time; // 以秒为单位

                    const record = {
                        fileName: this.fileList[0].name,
                        conversionType: this.form.option,
                        downloadUrl: this.form.option === '6' ? null : downloadUrl,
                        base64String: this.form.option === '6' ? base64String : null,
                        countdown: '',
                        expireTimeInSeconds,
                        startTime: new Date().getTime()
                    };
                    this.conversionRecords.push(record);
                    this.startCountdown(record);
                } else {
                    this.$message.error('Conversion failed!');
                }
            } catch (error) {
                this.$message.error('Conversion failed!');
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        startCountdown(record) {
            const calculateTimeLeft = () => {
                const now = new Date().getTime();
                const expirationTime = record.startTime + record.expireTimeInSeconds * 1000;
                const timeLeft = expirationTime - now;

                if (timeLeft <= 0) {
                    record.countdown = 'Expired';
                    clearInterval(record.countdownInterval);
                } else {
                    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
                    record.countdown = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

                    // 直接更新数组项触发 Vue 响应式
                    this.conversionRecords = [...this.conversionRecords];
                }
            };

            calculateTimeLeft();
            record.countdownInterval = setInterval(calculateTimeLeft, 1000);
        },
        showBase64(base64String) {
            this.base64Content = base64String;
            this.base64DialogVisible = true;
        }
    },
    mounted() {
        this.upload = this.$refs.upload;
    },
    beforeUnmount() {
        this.conversionRecords.forEach(record => {
            if (record.countdownInterval) {
                clearInterval(record.countdownInterval);
            }
        });
    }
};
</script>

<style scoped>
.upload-demo {
    margin: 20px 0;
    border: 2px dashed #d9d9d9;
    border-radius: 6px;
    background-color: #f9f9f9;
    padding: 40px 20px;
    text-align: center;
}

.el-upload__text {
    font-size: 16px;
    color: #606266;
}

.el-upload__tip {
    margin-top: 10px;
    font-size: 14px;
    color: #909399;
}

.form-container {
    margin-top: 20px;
}

.conversion-table {
    margin-top: 20px;
    width: 100%;
}

.loading-icon {
    margin-left: 5px;
}
</style>
