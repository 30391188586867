<template>
    <el-container>
      <el-aside class="el-aside" v-if="!isMobile">
        <el-menu :default-active="activeIndex" class="el-menu-vertical-demo" :default-openeds="['/convert', '/developer']" @open="handleOpen" @close="handleClose" @select="handleSelect">
          <el-menu-item index="/">Home</el-menu-item>
          <el-sub-menu index="/convert">
            <template #title>Format Convert</template>
            <el-menu-item index="/convert/document">Document</el-menu-item>
            <el-menu-item index="/convert/markdown">Markdown</el-menu-item>
            <el-menu-item index="/convert/image">Image</el-menu-item>
          </el-sub-menu>
          <el-sub-menu index="/developer">
            <template #title>Developer Tools</template>
            <el-menu-item index="/developer/timestamp">Timestamp</el-menu-item>
            <el-menu-item index="/developer/json-formatter">Json Formatter</el-menu-item>
            <el-menu-item index="/developer/javascript">JavaScript</el-menu-item>
            <el-menu-item index="/developer/base64-encoder-decoder">Base64 Tool</el-menu-item>
            <el-menu-item index="/developer/url-encoder-decoder">Url encoder / Url decoder</el-menu-item>
            <!-- <el-menu-item index="/developer/pixelated-image">Pixelated Image</el-menu-item>
            <el-menu-item index="/developer/image-watermark">Watermark</el-menu-item> -->
          </el-sub-menu>
          <el-menu-item index="/privacy">Privacy Policy</el-menu-item>
        </el-menu>
      </el-aside>
      <el-header class="el-header" style="padding-left: 0; padding-right: 0;" v-else>
        <el-menu mode="horizontal" :default-active="activeIndex" class="el-menu-horizontal-demo" @select="handleSelect">
          <el-menu-item index="/">Home</el-menu-item>
          <el-sub-menu index="/convert">
            <template #title>Tools</template>
            <el-menu-item index="/convert/document">Document</el-menu-item>
            <el-menu-item index="/convert/markdown">Markdown</el-menu-item>
            <el-menu-item index="/convert/image">Image</el-menu-item>
            <el-menu-item index="/developer/timestamp">Timestamp</el-menu-item>
            <el-menu-item index="/developer/json-formatter">Json Formatter</el-menu-item>
            <el-menu-item index="/developer/javascript">JavaScript</el-menu-item>
            <el-menu-item index="/developer/base64-encoder-decoder">Base64 Tool</el-menu-item>
            <el-menu-item index="/developer/url-encoder-decoder">Url encoder / Url decoder</el-menu-item>
            <!-- <el-menu-item index="/developer/pixelated-image">Pixelated Image</el-menu-item>
            <el-menu-item index="/developer/image-watermark">Watermark</el-menu-item> -->
            <el-menu-item index="/privacy">Privacy Policy</el-menu-item>
          </el-sub-menu>
          <!-- <el-sub-menu index="/developer">
            <template #title>Developer Tools</template>
            <el-menu-item index="/developer/timestamp">Timestamp</el-menu-item>
            <el-menu-item index="/developer/json-formatter">Json Formatter</el-menu-item>
            <el-menu-item index="/developer/javascript">JavaScript</el-menu-item>
          </el-sub-menu> -->
        </el-menu>
      </el-header>
    </el-container>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  
  export default {
    name: 'App',
    props: {
      currentPath: {
        type: String,
        required: true
      }
    },
    setup(props) {
      const router = useRouter();
      const activeIndex = ref('');
      const isMobile = ref(false);
  
      const handleResize = () => {
        isMobile.value = window.innerWidth <= 768;
      };
  
      onMounted(() => {
        // console.log("path:", props.currentPath);
        activeIndex.value = props.currentPath;
        handleResize();
        window.addEventListener('resize', handleResize);
      });
  
      const handleSelect = (key, keyPath) => {
        console.log(keyPath.length);
        
        router.push(key);
      };
      const handleOpen = (key, keyPath) => {
        console.log(key, keyPath);
      };
      const handleClose = (key, keyPath) => {
        console.log(key, keyPath);
      };
  
      return {
        activeIndex,
        handleSelect,
        handleOpen,
        handleClose,
        isMobile
      };
    }
  };
  </script>
  
  <style scoped>
  .el-aside {
    width: 200px;
    position: fixed;
    top: 0;
    bottom: 0;
  }
  
  .el-header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index:999;
  }
  
  .el-menu-vertical-demo {
    height: 100%;
  }
  
  .el-menu-horizontal-demo {
    width: 100%;
    justify-content: center;
  }
  
  @media (max-width: 768px) {
    .el-aside {
      display: none;
    }
  
    .el-header {
      display: flex;
    }
  }
  </style>
  