<template>
    <el-container>
        <MenuView currentPath="/convert/markdown" />
        <el-main class="right-panel">
            <el-card class="box-card" style="margin-bottom: 20px;">
                <h3>Markdown Conversion Tool</h3>
                <p>This tool allows you to convert Markdown documents to HTML or PDF format. Follow these steps to use the tool:</p>
                <ol>
                    <li>Upload a Markdown file (.md) by dragging it to the upload area or clicking to select a file.</li>
                    <li>Select the desired conversion option: HTML or PDF.</li>
                    <li>Click the "Convert" button to start the conversion process.</li>
                    <li>Once the conversion is complete, you can download the converted file. Note that the converted files will expire and be deleted after a certain period.</li>
                </ol>
                <!-- <p>The following languages are supported for code block syntax highlighting:</p>
                <ul>
                    <li>JavaScript</li>
                    <li>Python</li>
                    <li>Java</li>
                    <li>Rust</li>
                    <li>Go</li>
                    <li>C++</li>
                    <li>C</li>
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>Dart</li>
                </ul> -->
            </el-card>
            <el-upload
                ref="upload"
                class="upload-demo"
                action="#"
                drag
                :auto-upload="false"
                :limit="1"
                accept=".md"
                :on-change="handleFileChange"
                :on-exceed="handleExceed"
                :before-upload="beforeUpload"
                :on-remove="handleRemove"
                :file-list="fileList"
            >
                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                <div class="el-upload__text">
                    Drop file here or <em>click to upload</em>
                </div>
                <template #tip>
                    <div class="el-upload__tip">
                        Currently, the supported document format is Markdown (.md), and the document size cannot exceed 10MB.
                    </div>
                </template>
            </el-upload>
            <el-form ref="form" :model="form" label-width="160px" class="form-container">
                <el-form-item label="Conversion Options">
                    <el-radio-group v-model="form.option">
                        <el-radio value="7">To HTML</el-radio>
                        <el-radio value="8">To PDF</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSubmit" :loading="loading">
                        Convert
                        <el-icon v-if="loading" class="loading-icon"><loading /></el-icon>
                    </el-button>
                </el-form-item>
            </el-form>
            <el-table :data="conversionRecords" v-if="conversionRecords.length > 0" class="conversion-table">
                <el-table-column prop="fileName" label="File Name" width="180"></el-table-column>
                <el-table-column prop="conversionType" label="Conversion Type" width="180">
                    <template #default="scope">
                        {{ conversionOptions[scope.row.conversionType] }}
                    </template>
                </el-table-column>
                <el-table-column prop="downloadUrl" label="Download Link">
                    <template #default="scope">
                        <a :href="scope.row.downloadUrl" target="_blank">Download</a>
                    </template>
                </el-table-column>
                <el-table-column prop="countdown" label="Expiration">
                    <template #default="scope">
                        {{ scope.row.countdown }}
                    </template>
                </el-table-column>
                <el-table-column label="Note">
                    <template #default>
                        File will be deleted after expiration.
                    </template>
                </el-table-column>
            </el-table>
            <el-divider></el-divider>
            <div><strong>Version 1.0.0</strong> (2024-05-26)</div>
            <p>Free online Markdown(.md) convert to html\pdf tool</p>
            <FooterView />
        </el-main>
        <!-- <el-footer>
            
        </el-footer> -->
    </el-container>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import { genFileId } from 'element-plus';
import { useHead } from '@vueuse/head'
export default {
    setup() {
        useHead({
            title: 'Free online Convert markdown to PDF HTML',
            meta: [
                {
                    name: 'keywords',
                    content: 'markdown to pdf,markdown to html,md to pdf,md to html'
                },
                {
                    name: 'description',
                    content: 'online convert markdown file to pdf or html'
                }
            ]
        });
    },
    data() {
        return {
            fileList: [],
            form: {
                option: '7'
            },
            conversionRecords: [],
            loading: false,
            upload: ref(null),
            conversionOptions: {
                '7': 'To HTML',
                '8': 'To PDF'
            }
        };
    },
    methods: {
        handleExceed(files) {
            console.log('Exceed files:', files);
            this.upload.clearFiles();
            const file = files[0];
            file.uid = genFileId();
            this.upload.handleStart(file);
        },
        handleFileChange(file, fileList) {
            this.fileList = fileList;
        },
        beforeUpload(file) {
            // Check file size before uploading
            if (file.size / 1024 / 1024 > 10) {
                this.$message.error('File size cannot exceed 10MB!');
                return false;
            }
            return true;
        },
        handleRemove(file, fileList) {
            this.fileList = fileList;
        },
        removeFile(file) {
            const index = this.fileList.indexOf(file);
            if (index > -1) {
                this.fileList.splice(index, 1);
            }
        },
        async handleSubmit() {
            if (this.fileList.length === 0) {
                this.$message.error('Please select a file!');
                return;
            }

            this.loading = true;

            const formData = new FormData();
            formData.append('file', this.fileList[0].raw);
            formData.append('ct', this.form.option);
            const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
            try {
                const response = await axios.post(apiBaseUrl + '/api/convert/markdown', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if (response.data.code === 0) {
                    this.$message.success('File converted successfully!');
                    const downloadUrl = response.data.data.url;
                    const expireTimeInSeconds = response.data.data.expire_time; // 以秒为单位

                    const record = {
                        fileName: this.fileList[0].name,
                        conversionType: this.form.option,
                        downloadUrl,
                        countdown: '',
                        expireTimeInSeconds,
                        startTime: new Date().getTime()
                    };
                    this.conversionRecords.push(record);
                    this.startCountdown(record);
                } else {
                    this.$message.error('Conversion failed!');
                }
            } catch (error) {
                this.$message.error('Conversion failed!');
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        startCountdown(record) {
            const calculateTimeLeft = () => {
                const now = new Date().getTime();
                const expirationTime = record.startTime + record.expireTimeInSeconds * 1000;
                const timeLeft = expirationTime - now;

                if (timeLeft <= 0) {
                    record.countdown = 'Expired';
                    clearInterval(record.countdownInterval);
                } else {
                    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
                    record.countdown = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

                    // 直接更新数组项触发 Vue 响应式
                    this.conversionRecords = [...this.conversionRecords];
                }
            };

            calculateTimeLeft();
            record.countdownInterval = setInterval(calculateTimeLeft, 1000);
        }
    },
    mounted() {
        this.upload = this.$refs.upload;
    },
    beforeUnmount() {
        this.conversionRecords.forEach(record => {
            if (record.countdownInterval) {
                clearInterval(record.countdownInterval);
            }
        });
    }
};
</script>

<style scoped>
.upload-demo {
    margin: 20px 0;
    border: 2px dashed #d9d9d9;
    border-radius: 6px;
    background-color: #f9f9f9;
    padding: 40px 20px;
    text-align: center;
}

.el-upload__text {
    font-size: 16px;
    color: #606266;
}

.el-upload__tip {
    margin-top: 10px;
    font-size: 14px;
    color: #909399;
}

.form-container {
    margin-top: 20px;
}

.conversion-table {
    margin-top: 20px;
    width: 100%;
}

.loading-icon {
    margin-left: 5px;
}
</style>
