<template>
    <MenuView currentPath="/developer/base64-encoder-decoder" />
    <el-container class="right-panel">
      <el-main>
        <el-form @submit.prevent>
          <el-form-item label="Input">
            <el-input type="textarea" v-model="inputText" placeholder="Paste your text or Base64 here" rows="10"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="encodeBase64">Encode</el-button>
            <el-button type="primary" @click="decodeBase64">Decode</el-button>
            <el-button type="danger" @click="clearText">Clear</el-button>
          </el-form-item>
        </el-form>
        <el-form-item label="Output">
          <el-input type="textarea" :value="outputText" placeholder="Output will appear here" rows="10" readonly></el-input>
        </el-form-item>
        <el-alert v-if="error" type="error" :closable="false" title="Error" :description="errorMessage"></el-alert>
        <el-divider></el-divider>
        <h3>Introduction</h3>
        <p>
          The Base64 Encoder/Decoder page is a web tool designed to help developers and users easily encode and decode Base64 data. This tool allows you to convert text to Base64 encoding and decode Base64 data back to plain text.
        </p>
        <el-divider></el-divider>
        <h3>Key Features:</h3>
        <p><strong>Input Field:</strong> Allows users to paste or type their raw text or Base64 data into a text area.</p>
        <p><strong>Encode Button:</strong> Encodes the input text to Base64 format.</p>
        <p><strong>Decode Button:</strong> Decodes the Base64 data back to plain text.</p>
        <p><strong>Clear Button:</strong> Clears the input and output fields for new data.</p>
        <p><strong>Error Handling:</strong> Highlights and displays error messages for invalid Base64 data, helping users to quickly identify and fix issues.</p>
        <p><strong>Output:</strong> Displays the encoded or decoded data in a separate text area, which can be easily copied for use elsewhere.</p>
        <p>
          This tool is especially useful for developers who work with data encoding and transmission, ensuring that data is properly encoded and decoded for various applications.
        </p>
        <el-divider></el-divider>
        <div><strong>Version 1.0.0</strong> (2024-06-20)</div>
        <p>Free online base64 encoder/decoder tool</p>
        <FooterView />
      </el-main>
    </el-container>
  </template>
  
  <script>
  import { ref } from 'vue';
  import { useHead } from '@vueuse/head';
  
  export default {
    name: 'Base64EncoderDecoder',
    props: {
      initialInput: {
        type: String,
        default: ''
      }
    },
    setup(props) {
      const inputText = ref(props.initialInput);
      var output = '';
      if (props.initialInput !== ''){
        output = atob(props.initialInput);
      }
      const outputText = ref(output);
      const error = ref(false);
      const errorMessage = ref('');
  
      useHead({
        title: 'Free Base64 Encoder/Decoder',
        meta: [
          {
            name: 'keywords',
            content: 'base64 encode, base64 decode, online base64 encoder, online base64 decoder, free online base64'
          },
          {
            name: 'description',
            content: 'Free online tool to encode and decode Base64 data'
          }
        ]
      });
  
      const encodeBase64 = () => {
        try {
          error.value = false;
          errorMessage.value = '';
          outputText.value = btoa(inputText.value);
        } catch (err) {
          error.value = true;
          errorMessage.value = 'Failed to encode the text. Please ensure the input is valid.';
          outputText.value = '';
        }
      };
  
      const decodeBase64 = () => {
        try {
          error.value = false;
          errorMessage.value = '';
          outputText.value = atob(inputText.value);
        } catch (err) {
          error.value = true;
          errorMessage.value = 'Failed to decode the text. Please ensure the input is valid Base64.';
          outputText.value = '';
        }
      };
  
      const clearText = () => {
        inputText.value = '';
        outputText.value = '';
        error.value = false;
        errorMessage.value = '';
      };
  
      return {
        inputText,
        outputText,
        error,
        errorMessage,
        encodeBase64,
        decodeBase64,
        clearText
      };
    }
  };
  </script>
  
  <style scoped>
  .el-main {
    padding: 20px;
  }
  
  p {
    font-size: 14px;
    color: grey;
  }
  </style>
  