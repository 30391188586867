<template>
    <el-container>
        <MenuView currentPath="/" />
        <el-main class="right-panel">
            <!-- Hero Section -->
            <div class="hero-section">
                <div class="hero-content">
                    <h1>Welcome to Argzz.com, the Multi-Tool Site</h1>
                    <p>Providing various format conversion and developer tools</p>
                </div>
            </div>
            <!-- Site Introduction Section -->
            <div class="intro-section">
                <h2>About Our Site</h2>
                <p>
                    Our multi-tool site offers a wide range of utilities designed to make your life easier. Whether you
                    need to convert documents, images, or markdown files, we have the tools you need. Developers will
                    also find useful tools for handling timestamps, formatting JSON, working with JavaScript, url encoder/decoder and
                    encoding/decoding Base64. Explore our recommended tools and discover how they can help you with your
                    daily tasks.
                </p>
            </div>
            <!-- Recommended Tools Section -->
            <div class="recommended-section">
                <h2>Recommended Tools</h2>
                <el-carousel :interval="5000" arrow="always">
                    <el-carousel-item v-for="tool in recommendedTools" :key="tool.name">
                        <el-card :body-style="{ padding: '20px' }" @click="navigateTo(tool.route)">
                            <div class="tool-icon"><img :src="tool.icon" :alt="tool.name" /></div>
                            <h3>{{ tool.name }}</h3>
                            <p>{{ tool.description }}</p>
                        </el-card>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <!-- Tools Section -->
            <div class="tools-section">
                <h2>Format Conversion Tools</h2>
                <el-row :gutter="20">
                    <el-col :xs="24" :sm="12" :md="8" v-for="tool in formatTools" :key="tool.name">
                        <el-card :body-style="{ padding: '20px' }" @click="navigateTo(tool.route)">
                            <div class="tool-icon"><img :src="tool.icon" :alt="tool.name" /></div>
                            <h3>{{ tool.name }}</h3>
                            <p>{{ tool.description }}</p>
                        </el-card>
                    </el-col>
                </el-row>

                <h2>Developer Tools</h2>
                <el-row :gutter="20">
                    <el-col :xs="24" :sm="12" :md="8" v-for="tool in devTools" :key="tool.name">
                        <el-card :body-style="{ padding: '20px' }" @click="navigateTo(tool.route)">
                            <div class="tool-icon"><img :src="tool.icon" :alt="tool.name" /></div>
                            <h3>{{ tool.name }}</h3>
                            <p>{{ tool.description }}</p>
                        </el-card>
                    </el-col>
                </el-row>
            </div>

            <FooterView />
        </el-main>
    </el-container>
</template>

<style>
.logo {
    float: left;
    font-size: 20px;
    color: #333;
    line-height: 60px;
    padding: 0 20px;
}

.hero-section {
    text-align: left;
    padding: 20px 20px;
    color: #333;
}

.hero-content h1 {
    font-size: 36px;
    margin-bottom: 20px;
}

.hero-content p {
    font-size: 18px;
    margin-bottom: 40px;
}

.intro-section {
    padding: 20px 20px;
    text-align: left;
    color: #333;
}

.intro-section h2 {
    font-size: 28px;
    margin-bottom: 20px;
}

.intro-section p {
    font-size: 18px;
    line-height: 1.5;
}

.tools-section {
    padding: 20px 0;
    text-align: center;
}

.tools-section h2 {
    font-size: 28px;
    margin-bottom: 20px;
}

.tool-icon img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}

.el-card:hover {
    cursor: pointer;
}

.el-card {
    margin-top: 13px;
}

.recommended-section {
    padding: 50px 0;
    text-align: center;
}

.recommended-section h2 {
    font-size: 28px;
    margin-bottom: 20px;
}

.footer-content {
    text-align: center;
    padding: 20px 0;
}

.social-links a {
    margin: 0 10px;
    color: #333;
}

@media (max-width: 768px) {
    .hero-section {
        padding: 50px 10px;
    }

    .right-panel {
        padding: 0 !important;
    }
}
</style>

<script>
import { useHead } from '@vueuse/head'
export default {
    setup() {
        useHead({
            title: 'Online Tools - Argzz.com',
            meta: [
                {
                    name: 'keywords',
                    content: 'online tools, format convert, developer tools, convert docx to pdf, timestamp, json formatter'
                },
                {
                    name: 'description',
                    content: 'providing high-quality online tools for various purposes.'
                }
            ]
        });
    },
    data() {
        return {
            activeIndex: '1',
            formatTools: [
                { name: 'Word Document', description: 'Convert to HTML and PDF', icon: '/icons/word-icon.png', route: '/convert/document' },
                { name: 'Image', description: 'Convert png, jpg, webp to jpg, png, webp, base64', icon: '/icons/image-icon.png', route: '/convert/image' },
                { name: 'Markdown', description: 'Convert markdown files to HTML and PDF', icon: '/icons/markdown-icon.png', route: '/convert/markdown' }
            ],
            devTools: [
                { name: 'Timestamp', description: 'Convert timestamp and datetime, show common language timestamp methods', icon: '/icons/timestamp-icon.png', route: '/developer/timestamp' },
                { name: 'JSON', description: 'Online JSON formatter', icon: '/icons/json-icon.png', route: '/developer/json-formatter' },
                { name: 'JavaScript', description: 'Format, compress, obfuscate, encrypt, decrypt JavaScript code', icon: '/icons/javascript-icon.png', route: '/developer/javascript' },
                { name: 'Base64 Encoder/Decoder', description: 'Encode and decode Base64 strings', icon: '/icons/base64-icon.png', route: '/developer/base64-encoder-decoder' },
                { name: 'Url Encoder/Decoder', description: 'Encode and decode Url strings', icon: '/icons/url-icon.png', route: '/developer/url-encoder-decoder' }
            ],
            recommendedTools: [
                { name: 'Word Document', description: 'Convert to HTML and PDF', icon: '/icons/word-icon.png', route: '/convert/document' },
                { name: 'Image', description: 'Convert png, jpg, webp to jpg, png, webp, base64', icon: '/icons/image-icon.png', route: '/convert/image' },
                { name: 'Timestamp', description: 'Convert timestamp and datetime', icon: '/icons/timestamp-icon.png', route: '/developer/timestamp' }
            ]
        };
    },
    methods: {
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
        navigateTo(route) {
            this.$router.push(route);
        }
    }
};
</script>
