<template>
    <MenuView currentPath="/developer/json-formatter" />
    <el-container class="right-panel">
        <el-main>
            <el-form @submit.prevent="formatJson">
                <el-form-item label="JSON Input">
                    <el-input type="textarea" v-model="jsonInput" placeholder="Paste your JSON here"
                        rows="10"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="formatJson">Format JSON</el-button>
                    <el-button type="danger" @click="clearJson">Clear</el-button>
                </el-form-item>
            </el-form>
            <el-form-item label="Formatted JSON">
                <el-input type="textarea" :value="jsonOutput" placeholder="Formatted JSON will appear here" rows="10"
                    readonly></el-input>
            </el-form-item>
            <el-alert v-if="error" type="error" :closable="false" title="Invalid JSON"
                :description="errorMessage"></el-alert>
            <el-divider></el-divider>
            <h3>Introduction</h3>
            <p>
                The JSON Formatter page is a web tool designed to help developers and users easily format and validate
                JSON data. This tool takes unformatted or minified JSON data and converts it into a readable and
                well-structured format. It also checks the validity of the JSON data, highlighting any syntax errors,
                making it easier to debug and ensure the correctness of the JSON data.
            </p>
            <el-divider></el-divider>
            <h3>Key Features:</h3>
            <p><strong>Javascript Input Field:</strong> Allows users to paste or type their raw JSON data into a text
                area.</p>
            <p><strong>Format Button:</strong> Formats the input JSON data, making it human-readable with proper
                indentation.</p>
            <p><strong>Clear Button:</strong> Clears the input and output fields for new JSON data.</p>
            <p><strong>Error Handling:</strong> Highlights and displays error messages for invalid JSON data, helping
                users to quickly identify and fix syntax errors.</p>
            <p><strong>Formatted JSON Output:</strong> Displays the formatted JSON data in a separate text area, which
                can be easily copied for use elsewhere.</p>
            <p>
                This tool is especially useful for developers who work with APIs, configuration files, or any
                application that requires JSON data manipulation. It ensures that JSON data is properly formatted and
                error-free, facilitating easier data handling and debugging.
            </p>
            <el-divider></el-divider>
                <div><strong>Version 1.0.0</strong> (2024-05-23)</div>
                <p>Free online JSON formater</p>
            <FooterView />
        </el-main>
        <!-- <el-footer>
            <FooterView />
        </el-footer> -->
    </el-container>
</template>

<script>
import jsonlint from 'jsonlint-mod';
import { useHead } from '@vueuse/head'
export default {
    setup() {
        useHead({
            title: 'Free online Json formatter',
            meta: [
                {
                    name: 'keywords',
                    content: 'json format, online format json'
                },
                {
                    name: 'description',
                    content: 'online format json tool, can format json data'
                }
            ]
        });
    },
    name: 'JsonFormatter',
    data() {
        return {
            jsonInput: '',
            jsonOutput: '',
            error: false,
            errorMessage: ''
        };
    },
    components: {
    },
    methods: {
        formatJson() {
            try {
                this.error = false;
                this.errorMessage = '';
                const parsedJson = jsonlint.parse(this.jsonInput);
                this.jsonOutput = JSON.stringify(parsedJson, null, 2);
            } catch (err) {
                this.error = true;
                this.errorMessage = err.message;
                this.jsonOutput = '';
            }
        },
        clearJson() {
            this.jsonInput = '';
            this.jsonOutput = '';
            this.error = false;
            this.errorMessage = '';
        }
    }
};
</script>

<style>

.el-main {
    padding: 20px;
}

p {
    font-size: 14px;
    color: grey;
}
@media (max-width: 768px) {

}
</style>