// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import PrivacyView from '@/views/PrivacyView.vue';
import DocumentView from '@/views/DocumentView.vue';
import MarkdownView from '@/views/MarkdownView.vue';
import ImageConvertView from '@/views/ImageConvertView.vue';
import TimestampView from '@/views/TimestampView.vue';
import JsonFormatterView from '@/views/JsonFormatter.vue';
import JavascriptView from '@/views/JavascriptView.vue';
import Base64EncoderDecoderView from '@/views/Base64EncoderDecoder.vue';
import UrlEncoderDecoderView from '@/views/UrlEncoderDecoder.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/convert/document',
    name: 'Document',
    component: DocumentView,
  },
  {
    path: '/convert/markdown',
    name: 'Markdown',
    component: MarkdownView,
  },
  {
    path: '/convert/image',
    name: 'ImageConvert',
    component: ImageConvertView,
  },
  {
    path: '/developer/timestamp',
    name: 'Timestamp',
    component: TimestampView,
  },
  {
    path: '/developer/url-encoder-decoder',
    name: 'UrlEncoder/UrlDecoder',
    component: UrlEncoderDecoderView,
  },
  {
    path: '/developer/json-formatter',
    name: 'JsonFormatter',
    component: JsonFormatterView,
  },
  {
    path: '/developer/javascript',
    name: 'Javascript',
    component: JavascriptView,
  },
  {
    path: '/developer/base64-encoder-decoder',
    name: 'Base64EncoderDecoder',
    component: Base64EncoderDecoderView,
    props: route => ({ initialInput: route.query.initialInput })
  },
  {
    path: '/privacy',
    name: 'Privacy Policy',
    component: PrivacyView,
  },
  // other routes
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
