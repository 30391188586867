<template>
  <el-container>
    <!-- <el-header>
      <el-menu :default-active="$route.path" class="el-menu-demo" mode="horizontal" router>
        <el-menu-item index="/">首页</el-menu-item>
        <el-menu-item index="/about">关于我们</el-menu-item>
      </el-menu>
    </el-header> -->

    <!-- <el-main> -->
    <router-view></router-view>
    <!-- </el-main> -->

    <!-- <el-footer>
      <el-row>
        <el-col :span="4"><el-button type="text" @click="navigateTo('/')">首页</el-button></el-col>
        <el-col :span="4"><el-button type="text" @click="navigateTo('/about')">关于我们</el-button></el-col>

      </el-row>
    </el-footer> -->
  </el-container>
</template>

<script>
export default {
  name: 'App',
  methods: {
    navigateTo(route) {
      this.$router.push(route);
    }
  }
};
</script>

<style>
body {
  font-family: 'Roboto', sans-serif;
}

.el-header,
.el-footer {
  /* background-color: #b3c0d1; */
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-menu-demo {
  /* background-color: #b3c0d1; */
}

.el-main {
  padding: 20px;
}

.right-panel {
  margin-left: 200px;
}

@media (max-width: 768px) {
  .right-panel {
    margin-left: 0px;
  }

  .el-main {
    margin-top: 60px;
    /* Ensure this matches the height of your header */
  }
}
</style>
