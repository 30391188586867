<template>
    <MenuView currentPath="/developer/url-encoder-decoder" />
    <el-container class="right-panel">
        <el-main class="url-encoder-decoder">
            <h2>URL Encoder/Decoder</h2>
            <el-form>
                <el-form-item label="Input">
                    <el-input type="textarea" placeholder="Enter text to encode or decode" v-model="inputText"
                        rows="5"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="encodeUrl">Encode</el-button>
                    <el-button type="success" @click="decodeUrl">Decode</el-button>
                </el-form-item>
                <el-form-item label="Result">
                    <el-input type="textarea" placeholder="Result will be shown here" v-model="resultText" rows="5"
                        readonly></el-input>
                </el-form-item>
            </el-form>
            <!-- URL Encoding Rules -->
            <div class="url-encoding-rules">
                <h3>URL Encoding Rules</h3>
                <p>URL encoding follows the rules below:</p>
                <ul>
                    <li>Each name/value pair is separated by the `&` character.</li>
                    <li>Each name/value pair from a form is separated by the `=` character.</li>
                    <li>If a name has no value, it will still appear without a value.</li>
                    <li>Any special characters (such as non-ASCII characters, like Chinese characters) are encoded with
                        a percent sign `%` followed by the hexadecimal representation.</li>
                    <li>Special characters include `=`, `&`, `%`, etc.</li>
                    <li>The ASCII code of a character is URL-encoded by prefixing it with `%`, e.g., the encoding for
                        `\` is `%5C`.</li>
                </ul>
            </div>

            <!-- URL 编码对照表 -->
            <div class="url-encoding-table">
                <h3>URL Encoding Table</h3>
                <el-table :data="encodingTable" border stripe>
                    <el-table-column prop="char" label="Character" width="100"></el-table-column>
                    <el-table-column prop="ascii" label="ASCII Code" width="120"></el-table-column>
                    <el-table-column prop="encoding" label="URL Encoding" width="150"></el-table-column>
                </el-table>
            </div>
            <el-divider></el-divider>
            <div><strong>Version 1.0.0</strong> (2024-08-16)</div>
            <p>Free online url encoder / url decoder tool</p>
            <FooterView />
        </el-main>
    </el-container>
</template>

<script>
import { useHead } from '@vueuse/head'
export default {

    setup() {
        useHead({
            title: 'Free Url Encoder/Decoder tool',
            meta: [
                {
                    name: 'keywords',
                    content: 'url encoder, url decoder, url query encode, url query decode'
                },
                {
                    name: 'description',
                    content: 'online url encode decode'
                }
            ]
        });
    },
    data() {
        return {
            inputText: '',
            resultText: '',
            encodingTable: [
                { char: 'backspace', ascii: '08', encoding: '%08' },
                { char: 'tab', ascii: '09', encoding: '%09' },
                { char: 'linefeed', ascii: '0A', encoding: '%0A' },
                { char: 'creturn', ascii: '0D', encoding: '%0D' },
                { char: 'space', ascii: '20', encoding: '%20' },
                { char: '!', ascii: '21', encoding: '%21' },
                { char: '"', ascii: '22', encoding: '%22' },
                { char: '#', ascii: '23', encoding: '%23' },
                { char: '$', ascii: '24', encoding: '%24' },
                { char: '%', ascii: '25', encoding: '%25' },
                { char: '&', ascii: '26', encoding: '%26' },
                { char: "'", ascii: '27', encoding: '%27' },
                { char: '(', ascii: '28', encoding: '%28' },
                { char: ')', ascii: '29', encoding: '%29' },
                { char: '*', ascii: '2A', encoding: '%2A' },
                { char: '+', ascii: '2B', encoding: '%2B' },
                { char: ',', ascii: '2C', encoding: '%2C' },
                { char: '-', ascii: '2D', encoding: '%2D' },
                { char: '.', ascii: '2E', encoding: '%2E' },
                { char: '/', ascii: '2F', encoding: '%2F' },
                { char: '0', ascii: '30', encoding: '%30' },
                { char: '1', ascii: '31', encoding: '%31' },
                { char: '2', ascii: '32', encoding: '%32' },
                { char: '3', ascii: '33', encoding: '%33' },
                { char: '4', ascii: '34', encoding: '%34' },
                { char: '5', ascii: '35', encoding: '%35' },
                { char: '6', ascii: '36', encoding: '%36' },
                { char: '7', ascii: '37', encoding: '%37' },
                { char: '8', ascii: '38', encoding: '%38' },
                { char: '9', ascii: '39', encoding: '%39' },
                { char: ':', ascii: '3A', encoding: '%3A' },
                { char: ';', ascii: '3B', encoding: '%3B' },
                { char: '<', ascii: '3C', encoding: '%3C' },
                { char: '=', ascii: '3D', encoding: '%3D' },
                { char: '>', ascii: '3E', encoding: '%3E' },
                { char: '?', ascii: '3F', encoding: '%3F' },
                { char: '@', ascii: '40', encoding: '%40' },
                { char: 'A', ascii: '41', encoding: '%41' },
                { char: 'B', ascii: '42', encoding: '%42' },
                { char: 'C', ascii: '43', encoding: '%43' },
                { char: 'D', ascii: '44', encoding: '%44' },
                { char: 'E', ascii: '45', encoding: '%45' },
                { char: 'F', ascii: '46', encoding: '%46' },
                { char: 'G', ascii: '47', encoding: '%47' },
                { char: 'H', ascii: '48', encoding: '%48' },
                { char: 'I', ascii: '49', encoding: '%49' },
                { char: 'J', ascii: '4A', encoding: '%4A' },
                { char: 'K', ascii: '4B', encoding: '%4B' },
                { char: 'L', ascii: '4C', encoding: '%4C' },
                { char: 'M', ascii: '4D', encoding: '%4D' },
                { char: 'N', ascii: '4E', encoding: '%4E' },
                { char: 'O', ascii: '4F', encoding: '%4F' },
                { char: 'P', ascii: '50', encoding: '%50' },
                { char: 'Q', ascii: '51', encoding: '%51' },
                { char: 'R', ascii: '52', encoding: '%52' },
                { char: 'S', ascii: '53', encoding: '%53' },
                { char: 'T', ascii: '54', encoding: '%54' },
                { char: 'U', ascii: '55', encoding: '%55' },
                { char: 'V', ascii: '56', encoding: '%56' },
                { char: 'W', ascii: '57', encoding: '%57' },
                { char: 'X', ascii: '58', encoding: '%58' },
                { char: 'Y', ascii: '59', encoding: '%59' },
                { char: 'Z', ascii: '5A', encoding: '%5A' },
                { char: '[', ascii: '5B', encoding: '%5B' },
                { char: '\\', ascii: '5C', encoding: '%5C' },
                { char: ']', ascii: '5D', encoding: '%5D' },
                { char: '^', ascii: '5E', encoding: '%5E' },
                { char: '_', ascii: '5F', encoding: '%5F' },
                { char: '`', ascii: '60', encoding: '%60' },
                { char: 'a', ascii: '61', encoding: '%61' },
                { char: 'b', ascii: '62', encoding: '%62' },
                { char: 'c', ascii: '63', encoding: '%63' },
                { char: 'd', ascii: '64', encoding: '%64' },
                { char: 'e', ascii: '65', encoding: '%65' },
                { char: 'f', ascii: '66', encoding: '%66' },
                { char: 'g', ascii: '67', encoding: '%67' },
                { char: 'h', ascii: '68', encoding: '%68' },
                { char: 'i', ascii: '69', encoding: '%69' },
                { char: 'j', ascii: '6A', encoding: '%6A' },
                { char: 'k', ascii: '6B', encoding: '%6B' },
                { char: 'l', ascii: '6C', encoding: '%6C' },
                { char: 'm', ascii: '6D', encoding: '%6D' },
                { char: 'n', ascii: '6E', encoding: '%6E' },
                { char: 'o', ascii: '6F', encoding: '%6F' },
                { char: 'p', ascii: '70', encoding: '%70' },
                { char: 'q', ascii: '71', encoding: '%71' },
                { char: 'r', ascii: '72', encoding: '%72' },
                { char: 's', ascii: '73', encoding: '%73' },
                { char: 't', ascii: '74', encoding: '%74' },
                { char: 'u', ascii: '75', encoding: '%75' },
                { char: 'v', ascii: '76', encoding: '%76' },
                { char: 'w', ascii: '77', encoding: '%77' },
                { char: 'x', ascii: '78', encoding: '%78' },
                { char: 'y', ascii: '79', encoding: '%79' },
                { char: 'z', ascii: '7A', encoding: '%7A' },
                { char: '{', ascii: '7B', encoding: '%7B' },
                { char: '|', ascii: '7C', encoding: '%7C' },
                { char: '}', ascii: '7D', encoding: '%7D' },
                { char: '~', ascii: '7E', encoding: '%7E' },
                { char: '￠', ascii: 'A2', encoding: '%A2' },
                { char: '￡', ascii: 'A3', encoding: '%A3' },
                { char: '￥', ascii: 'A5', encoding: '%A5' },
                { char: '§', ascii: 'A7', encoding: '%A7' },
                { char: '«', ascii: 'AB', encoding: '%AB' },
                { char: '¬', ascii: 'AC', encoding: '%AC' },
                { char: '¯', ascii: 'AD', encoding: '%AD' },
                { char: '°', ascii: 'B0', encoding: '%B0' },
                { char: '±', ascii: 'B1', encoding: '%B1' },
                { char: '´', ascii: 'B4', encoding: '%B4' },
                { char: 'μ', ascii: 'B5', encoding: '%B5' },
                { char: '»', ascii: 'BB', encoding: '%BB' },
                { char: '¼', ascii: 'BC', encoding: '%BC' },
                { char: '½', ascii: 'BD', encoding: '%BD' },
                { char: '¿', ascii: 'BF', encoding: '%BF' },
                { char: 'À', ascii: 'C0', encoding: '%C0' },
                { char: 'Á', ascii: 'C1', encoding: '%C1' },
                { char: 'Â', ascii: 'C2', encoding: '%C2' },
                { char: 'Ã', ascii: 'C3', encoding: '%C3' },
                { char: 'Ä', ascii: 'C4', encoding: '%C4' },
                { char: 'Å', ascii: 'C5', encoding: '%C5' },
                { char: 'Æ', ascii: 'C6', encoding: '%C6' },
                { char: 'Ç', ascii: 'C7', encoding: '%C7' },
                { char: 'È', ascii: 'C8', encoding: '%C8' },
                { char: 'É', ascii: 'C9', encoding: '%C9' },
                { char: 'Ê', ascii: 'CA', encoding: '%CA' },
                { char: 'Ë', ascii: 'CB', encoding: '%CB' },
                { char: 'Ì', ascii: 'CC', encoding: '%CC' },
                { char: 'Í', ascii: 'CD', encoding: '%CD' },
                { char: 'Î', ascii: 'CE', encoding: '%CE' },
                { char: 'Ï', ascii: 'CF', encoding: '%CF' },
                { char: 'Ñ', ascii: 'D1', encoding: '%D1' },
                { char: 'Ò', ascii: 'D2', encoding: '%D2' },
                { char: 'Ó', ascii: 'D3', encoding: '%D3' },
                { char: 'Ô', ascii: 'D4', encoding: '%D4' },
                { char: 'Õ', ascii: 'D5', encoding: '%D5' },
                { char: 'Ö', ascii: 'D6', encoding: '%D6' },
                { char: 'Ø', ascii: 'D8', encoding: '%D8' },
                { char: 'Ù', ascii: 'D9', encoding: '%D9' },
                { char: 'Ú', ascii: 'DA', encoding: '%DA' },
                { char: 'Û', ascii: 'DB', encoding: '%DB' },
                { char: 'Ü', ascii: 'DC', encoding: '%DC' },
                { char: 'ß', ascii: 'DF', encoding: '%DF' },
                { char: 'à', ascii: 'E0', encoding: '%E0' },
                { char: 'á', ascii: 'E1', encoding: '%E1' },
                { char: 'â', ascii: 'E2', encoding: '%E2' },
                { char: 'ã', ascii: 'E3', encoding: '%E3' },
                { char: 'ä', ascii: 'E4', encoding: '%E4' },
                { char: 'å', ascii: 'E5', encoding: '%E5' },
                { char: 'æ', ascii: 'E6', encoding: '%E6' },
                { char: 'ç', ascii: 'E7', encoding: '%E7' },
                { char: 'è', ascii: 'E8', encoding: '%E8' },
                { char: 'é', ascii: 'E9', encoding: '%E9' },
                { char: 'ê', ascii: 'EA', encoding: '%EA' },
                { char: 'ë', ascii: 'EB', encoding: '%EB' },
                { char: 'ì', ascii: 'EC', encoding: '%EC' },
                { char: 'í', ascii: 'ED', encoding: '%ED' },
                { char: 'î', ascii: 'EE', encoding: '%EE' },
                { char: 'ï', ascii: 'EF', encoding: '%EF' },
                { char: 'ð', ascii: 'F0', encoding: '%F0' },
                { char: 'ñ', ascii: 'F1', encoding: '%F1' },
                { char: 'ò', ascii: 'F2', encoding: '%F2' },
                { char: 'ó', ascii: 'F3', encoding: '%F3' },
                { char: 'ô', ascii: 'F4', encoding: '%F4' },
                { char: 'õ', ascii: 'F5', encoding: '%F5' },
                { char: 'ö', ascii: 'F6', encoding: '%F6' },
                { char: '÷', ascii: 'F7', encoding: '%F7' },
                { char: 'ø', ascii: 'F8', encoding: '%F8' },
                { char: 'ù', ascii: 'F9', encoding: '%F9' },
                { char: 'ú', ascii: 'FA', encoding: '%FA' },
                { char: 'û', ascii: 'FB', encoding: '%FB' },
                { char: 'ü', ascii: 'FC', encoding: '%FC' },
                { char: 'ý', ascii: 'FD', encoding: '%FD' },
                { char: 'þ', ascii: 'FE', encoding: '%FE' },
                { char: 'ÿ', ascii: 'FF', encoding: '%FF' }
            ]
        };
    },
    methods: {
        encodeUrl() {
            this.resultText = encodeURIComponent(this.inputText);
        },
        decodeUrl() {
            this.resultText = decodeURIComponent(this.inputText);
        }
    }
};
</script>

<style>

h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
}

h3 {
    font-size: 24px;
    margin-top: 20px;
    color: #666;
}

.el-form-item {
    margin-bottom: 20px;
}

.url-encoding-rules {
    margin-top: 40px;
}

.url-encoding-table {
    margin-top: 20px;
}
</style>
