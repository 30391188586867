<template>
    <el-container>
        <MenuView currentPath="/privacy" />
        <el-main class="right-panel">
    <div class="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>Last updated: June 5, 2024</p>
      <section>
        <h2>Introduction</h2>
        <p>
          Welcome to the Argzz.com, the Multi-Tool Site. We value your privacy and strive to protect your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website.
        </p>
        <p>
          Argzz.com is owned and operated by Summitmob Inc., This organization primarily operates in the Advertising Agencies business / industry within the Business Services sector.  This organization has been operating for approximately 7 years.
        </p>
        <p>
          Argzz.com dedicated to providing high-quality online tools for various purposes.
        </p>
      </section>
      <section>
        <h2>Information We Collect</h2>
        <p>We may collect the following types of personal data:</p>
        <ul>
          <li><strong>Personal Identification Information:</strong> Name, Email address, Phone number</li>
          <li><strong>Technical Data:</strong> IP address, Browser type and version, Time zone setting, Browser plug-in types and versions, Operating system and platform</li>
          <li><strong>Usage Data:</strong> Information about how you use our website, products, and services</li>
          <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to track activity on our website and hold certain information.</li>
          <li><strong>Analytics Data:</strong> Google Analytics: We use Google Analytics to collect information about your use of our website to improve our services.</li>
          <li><strong>Uploaded Documents:</strong> User-uploaded documents and images for conversion or other services. These documents are automatically deleted after 1 hour.</li>
        </ul>
      </section>
      <section>
        <h2>How We Use Your Information</h2>
        <p>We use the collected data for various purposes:</p>
        <ul>
          <li>To provide and maintain our website</li>
          <li>To notify you about changes to our website</li>
          <li>To allow you to participate in interactive features of our website when you choose to do so</li>
          <li>To provide customer support</li>
          <li>To gather analysis or valuable information so that we can improve our website</li>
          <li>To monitor the usage of our website</li>
          <li>To detect, prevent, and address technical issues</li>
          <li>To fulfill any other purpose for which you provide it</li>
        </ul>
      </section>
      <section>
        <h2>Disclosure of Your Information</h2>
        <p>We may disclose your personal information:</p>
        <ul>
          <li>To comply with a legal obligation</li>
          <li>To protect and defend our rights or property</li>
          <li>To prevent or investigate possible wrongdoing in connection with the website</li>
          <li>To protect the personal safety of users of the website or the public</li>
          <li>To protect against legal liability</li>
        </ul>
      </section>
      <section>
        <h2>Security of Your Information</h2>
        <p>
          The security of your personal information is important to us. We strive to use commercially acceptable means to protect your personal information, but remember that no method of transmission over the internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
        </p>
      </section>
      <section>
      <h2>Data Protection</h2>
      <h3>Personal Information</h3>
      <p>
        When visiting Argzz.com, the IP address used to access the site will be logged along with the dates and times of access. This information is purely used to analyze trends, administer the site, track users' movement, and gather broad demographic information for internal use. Most importantly, any recorded IP addresses are not linked to personally identifiable information.
      </p>
      <p>
        Argzz.com doesn't require registration to use. This means we DO NOT collect your name, email address, company name, password, etc.
      </p>
      <p>
        Argzz.com will never share your personal information with any third-party business organization that intends to use it for direct marketing purposes unless you have given us specific permission to do so. The processed information will only be stored as long as it is necessary for the purpose it was processed for or for as long as it is legally required.
      </p>
    </section>
    <section>
      <h3>Storage of Files</h3>
      <p>
        All the files uploaded for processing on Argzz.com are stored on an appropriate server located in Singapore or North America for processing and the download afterward. All user-uploaded files as well as the converted output files will be deleted in one hour after upload or conversion respectively. We keep the files for the sole purpose of giving you enough time to download them. During that time, we don't look at the files or mine any data from them. No backups are made of any uploaded files nor their processed output, and the contents are not monitored without the explicit permission of the user. We do not claim ownership of any of your files that you upload.
      </p>
    </section>
    <section>
      <h3>Data Security</h3>
      <p>
        All filenames are generated randomly and cannot be guessed. All communications between your web browser and the conversion servers are through a secure channel with HTTPS enabled, which prevents data from being altered or diverted. This completely protects your data from unauthorized access. All collected information on the website is protected from disclosure and unauthorized access by using physical, electronic, and managerial protection procedures.
      </p>
    </section>
    <section>
      <h3>Right of Objection</h3>
      <p>
        We allow you to access, modify, or delete the personal data collected by us. If you would like to do so, please contact us at support@argzz.com. We will get back to you as soon as possible.
      </p>
      <p>
        Please note that uploaded and processed files are never stored longer than one hour on our servers and are then deleted permanently. During this hour, your files are not accessed, copied, analyzed, or anything else unless you provide us with explicit written permission to do so. You can instantly delete your files without waiting. To do so, click the download icon next to your file, then click the "Delete File" button. This implies that you can delete your files on your own anytime. We can't make a backup copy of your files even if you ask us to do so.
      </p>
    </section>
      <section>
        <h2>Data Retention</h2>
        <p>
          We will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your personal information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.
        </p>
        <p><strong>Uploaded Documents:</strong> Any documents or images uploaded by users for conversion or other services will be automatically deleted after 1 hour.</p>
      </section>
      <section>
        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
        </p>
      </section>
      <section>
        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us:</p>
        <ul>
          <li>By email: richard@summitmob.us</li>
          <!-- <li>By visiting this page on our website: <a href="https://example.com/contact">Contact Us</a></li> -->
        </ul>
      </section>
    </div>
    <FooterView/>
    </el-main>
    </el-container>
  </template>
  
  <script>
import { useHead } from '@vueuse/head'
export default {
    setup() {
        useHead({
            title: 'Privacy police',
            meta: [
                {
                    name: 'keywords',
                    content: 'online convert tools'
                },
                {
                    name: 'description',
                    content: 'argzz.com privacy police'
                }
            ]
        });
    },
    name: 'PrivacyPolicy',
  };
  </script>
  
  <style scoped>
  .privacy-policy {
    padding: 20px;
  }
  .privacy-policy h1,
  .privacy-policy h2 {
    color: #333;
  }
  .privacy-policy ul {
    list-style-type: none;
    padding-left: 0;
  }
  .privacy-policy ul li {
    margin-bottom: 10px;
  }
  </style>
  