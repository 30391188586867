<template>
  <div class="footerV">
    <div class="text-line">
      Copyright © 2024 Summitmob Inc. All rights reserved.&nbsp;&nbsp;
      <a href="/privacy">Privacy</a>
    </div>
    <div class="text-line">
      Email: cmljaGFyZEBzdW1taXRtb2IudXM=&nbsp;&nbsp;
      <a href="/developer/base64-encoder-decoder?initialInput=cmljaGFyZEBzdW1taXRtb2IudXM=">
        Decode Email Info
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterView',
}
</script>

<style>
.footerV {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 14px;
  color: white;
  background-color: rgb(174, 171, 171);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.text-line {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 5px;
}

.text-line a {
  color: #ffffff;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footerV {
    font-size: 12px;
    padding: 5px;
  }

  .text-line {
    margin-bottom: 3px;
  }
}
</style>
