<template>
    <el-container>
        <MenuView currentPath="/developer/javascript" />
        <el-container class="right-panel">
            <el-main>
                <el-form @submit.prevent="processJavaScript">
                    <el-form-item label="JavaScript Input">
                        <el-input type="textarea" v-model="jsInput" placeholder="Paste your JavaScript code here"
                            rows="10"></el-input>
                    </el-form-item>
                    <el-form-item label="Select Action">
                        <el-radio-group v-model="selectedAction">
                            <el-radio label="format">Format</el-radio>
                            <el-radio label="compress">Compress</el-radio>
                            <el-radio label="obfuscate">Obfuscate</el-radio>
                            <el-radio label="encrypt">Encrypt</el-radio>
                            <el-radio label="decrypt">Decrypt</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="selectedAction === 'encrypt' || selectedAction === 'decrypt'">
                        <el-input type="password" v-model="encryptionKey" placeholder="Enter encryption key"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="processJavaScript">Run</el-button>
                        <el-button type="danger" @click="clearJavaScript">Clear</el-button>
                    </el-form-item>
                </el-form>
                <el-form-item label="Output">
                    <el-input type="textarea" :value="jsOutput" placeholder="Processed JavaScript will appear here"
                        rows="10" readonly></el-input>
                </el-form-item>
                <el-alert v-if="error" type="error" :closable="false" title="Error"
                    :description="errorMessage"></el-alert>
                <el-divider></el-divider>
                <h3>Introduction</h3>
                <p>
                    The JavaScript Toolkit is a comprehensive web tool designed to assist developers in managing their
                    JavaScript code efficiently. It provides functionalities for formatting, compressing, obfuscating,
                    encrypting, and decrypting JavaScript code. These tools can help enhance code readability, reduce
                    file size, protect code from reverse engineering, and secure code with encryption.
                </p>
                <el-divider></el-divider>
                <h3>Action:</h3>
                <p><strong>Format:</strong> Formats the input JavaScript code, making it more readable with proper
                    indentation and line breaks.</p>
                <p><strong>Compress:</strong> Compresses the input JavaScript code to reduce its size, which can improve
                    loading times and performance.</p>
                <p><strong>Obfuscate:</strong> Obfuscates the input JavaScript code, making it harder to understand and
                    thus protecting it from reverse engineering.</p>
                <p><strong>Encrypt:</strong> Encrypts the input JavaScript code using a specified key, ensuring that the
                    code is secure and unreadable without the key. To use the encrypted code, you need to decrypt it
                    using the same key and execute it within your application. Below is an example of how to decrypt and
                    execute the encrypted code:
                    <pre>
                        <code>
                            const encryptedCode = 'your-encrypted-code-here';
                            const key = 'your-encryption-key-here';
                            const bytes = CryptoJS.AES.decrypt(encryptedCode, key);
                            const decryptedCode = bytes.toString(CryptoJS.enc.Utf8);
                            eval(decryptedCode);
                        </code>
                    </pre>
                </p>
                <p><strong>Decrypt:</strong> Decrypts previously encrypted JavaScript code using the provided key,
                    restoring it to its original form.</p>
                <p>
                    This toolkit is especially useful for developers working with JavaScript applications, providing a
                    range of functionalities to improve code management and security.
                </p>
                <el-divider></el-divider>
                <div><strong>Version 1.0.0</strong> (2024-05-23)</div>
                <p>Free online javascript\JS Format\Compress\Obfuscate\Encrypt\Decrypt tool</p>
                <FooterView />
            </el-main>
            <!-- <el-footer>
                
            </el-footer> -->
        </el-container>
    </el-container>
</template>

<script>
import jsBeautify from 'js-beautify';
import { minify } from 'terser';
import JavaScriptObfuscator from 'javascript-obfuscator';
import CryptoJS from 'crypto-js';

import { useHead } from '@vueuse/head'
export default {
    setup() {
        useHead({
            title: 'Free Javascript online tool',
            meta: [
                {
                    name: 'keywords',
                    content: 'javascript format, js Compress, js Obfuscate, js Encrypt, js Decrypt'
                },
                {
                    name: 'description',
                    content: 'online js tool, can format, compress, obfuscate, encrypt, decrypt javascript code'
                }
            ]
        });
    },
    name: 'JsToolkit',
    data() {
        return {
            jsInput: '',
            jsOutput: '',
            selectedAction: '',
            encryptionKey: '',
            error: false,
            errorMessage: ''
        };
    },
    methods: {
        async processJavaScript() {
            try {
                this.error = false;
                this.errorMessage = '';

                switch (this.selectedAction) {
                    case 'format': {
                        this.jsOutput = jsBeautify.js(this.jsInput);
                        break;
                    }
                    case 'compress': {
                        const compressed = await minify(this.jsInput);
                        if (compressed.error) {
                            throw compressed.error;
                        }
                        this.jsOutput = compressed.code;
                        break;
                    }
                    case 'obfuscate': {
                        const obfuscationResult = JavaScriptObfuscator.obfuscate(this.jsInput);
                        this.jsOutput = obfuscationResult.getObfuscatedCode();
                        break;
                    }
                    case 'encrypt': {
                        if (!this.encryptionKey) {
                            throw new Error('Encryption key is required');
                        }
                        this.jsOutput = CryptoJS.AES.encrypt(this.jsInput, this.encryptionKey).toString();
                        break;
                    }
                    case 'decrypt': {
                        if (!this.encryptionKey) {
                            throw new Error('Encryption key is required');
                        }
                        const bytes = CryptoJS.AES.decrypt(this.jsInput, this.encryptionKey);
                        this.jsOutput = bytes.toString(CryptoJS.enc.Utf8);
                        break;
                    }
                    default:
                        throw new Error('Invalid action selected');
                }
            } catch (err) {
                this.error = true;
                this.errorMessage = err.message;
                this.jsOutput = '';
            }
        },
        clearJavaScript() {
            this.jsInput = '';
            this.jsOutput = '';
            this.selectedAction = '';
            this.encryptionKey = '';
            this.error = false;
            this.errorMessage = '';
        }
    }
};
</script>

<style scoped>
.el-main {
    padding: 20px;
}

p {
    font-size: 14px;
    color: grey;
}

pre {
    background-color: #f8f8f8;
    padding: 10px;
    border-radius: 5px;
    overflow-x: auto;
}

code {
    font-size: 14px;
    color: #333;
}
</style>