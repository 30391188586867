<template>
    <el-container>
        <MenuView currentPath="/developer/timestamp" />
        <el-container class="right-panel">
            <el-main>
                <div>
                    <div>Current Time: <span>{{ currentTimestamp }}</span></div>
                    <el-divider></el-divider>
                    <el-form label-width="100px">
                        <el-form-item label="Timestamp">
                            <el-input v-model="form.timestamp" placeholder="Enter timestamp" style="width: 200px;"
                                type="number"></el-input>
                            <el-select v-model="timestampUnit" placeholder="Unit" style="width: 200px; margin-left: 3px;margin-right: 3px">
                                <el-option label="Seconds (s)" value="s"></el-option>
                                <el-option label="Milliseconds (ms)" value="ms"></el-option>
                            </el-select>
                            <el-button type="primary" @click="convertTimestamp">Convert</el-button>
                        </el-form-item>
                        <el-form-item label="Date Time">
                            <el-input v-model="form.datetime" placeholder="Select date time"
                                style="width: 200px;"></el-input>
                            <el-select v-model="timezone" placeholder="Timezone" style="width: 200px; margin-left: 3px;margin-right: 3px">
                                <el-option v-for="tz in timezones" :key="tz.value" :label="tz.label"
                                    :value="tz.value"></el-option>
                            </el-select>
                            <el-button type="primary" @click="convertDatetime">Convert</el-button>
                        </el-form-item>
                    </el-form>
                    <el-divider></el-divider>
                    <div>
                        <h3>Introduction</h3>
                        <p>
                            A timestamp is the number of seconds that have elapsed since January 1, 1970 (midnight
                            UTC/GMT),
                            not counting leap seconds. This tool can quickly convert timestamps to human-readable
                            date and
                            time formats, and vice versa.
                        </p>
                    </div>
                    <el-divider></el-divider>
                    <div>
                        <h3>Get Current Timestamp</h3>
                        <el-table :data="codeSnippets" style="width: 100%">
                            <el-table-column prop="language" label="Language" width="120"></el-table-column>
                            <el-table-column label="Code">
                                <template v-slot="scope">
                                    <div class="code-container" @mouseover="showCopyButton(scope.$index)"
                                        @mouseleave="hideCopyButton(scope.$index)">
                                        <el-button v-if="visibleCopyButtons[scope.$index]" type="primary"
                                            icon="el-icon-document-copy" size="small" link
                                            @click="copyCode(scope.row.code)" class="copy-button">Copy</el-button>
                                        <pre>{{ scope.row.code }}</pre>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <el-divider></el-divider>
                <div><strong>Version 1.0.0</strong> (2024-05-28)</div>
                <p>Free online unix timestamp tool</p>
                <FooterView />
            </el-main>
            <!-- <el-footer>
                
            </el-footer> -->
        </el-container>

    </el-container>
</template>

<script>
import { ref, onMounted } from 'vue';
import moment from 'moment-timezone';
import { useRouter } from 'vue-router';
import { useHead } from '@vueuse/head'
export default {
    name: 'App',
    components: {
    },
    setup() {
        useHead({
            title: 'Free Timestamp convert tool',
            meta: [
                {
                    name: 'keywords',
                    content: 'timestamp, unix time, get unix time, get milliseconds, get seconds'
                },
                {
                    name: 'description',
                    content: 'online convert timestamp and datetime'
                }
            ]
        });
        const router = useRouter();
        const activeIndex = ref('3-1');
        const currentTimestamp = ref(Math.floor(Date.now() / 1000));
        const form = ref({
            timestamp: '',
            datetime: ''
        });
        const timestampUnit = ref('s');
        const timezone = ref('America/Los_Angeles');

        const visibleCopyButtons = ref([]);

        const showCopyButton = (index) => {
            visibleCopyButtons.value = [...visibleCopyButtons.value];
            visibleCopyButtons.value[index] = true;
        };

        const hideCopyButton = (index) => {
            visibleCopyButtons.value = [...visibleCopyButtons.value];
            visibleCopyButtons.value[index] = false;
        };

        const convertTimestamp = () => {
            const timestamp = parseInt(form.value.timestamp);
            if (isNaN(timestamp)) {
                alert('Please enter a valid timestamp');
                return;
            }
            const datetime = timestampUnit.value === 's' ? moment.unix(timestamp) : moment(timestamp);
            form.value.datetime = datetime.tz(timezone.value).format('YYYY-MM-DD HH:mm:ss');
        };

        const convertDatetime = () => {
            const datetime = moment.tz(form.value.datetime, timezone.value);
            if (!datetime.isValid()) {
                alert('Please enter a valid date time');
                return;
            }
            const timestamp = datetime.valueOf();
            form.value.timestamp = timestampUnit.value === 's' ? Math.floor(timestamp / 1000) : timestamp;
        };

        onMounted(() => {
            setInterval(() => {
                currentTimestamp.value = Math.floor(Date.now() / 1000);
            }, 1000);
            const ts = Math.floor(Date.now() / 1000);
            const datetime = timestampUnit.value === 's' ? moment.unix(ts) : moment(ts);
            form.value.datetime = datetime.tz(timezone.value).format('YYYY-MM-DD HH:mm:ss');
            form.value.timestamp = timestampUnit.value === 's' ? ('' + ts) : ('' + (ts * 1000));
        });

        const codeSnippets = ref([
            { language: 'Swift', code: 'NSDate().timeIntervalSince1970' },
            { language: 'Go', code: 'import (\n  "time"\n)\nint64(time.Now().Unix())' },
            { language: 'Java', code: '// pure java\nSystem.currentTimeMillis() / 1000\n\n// joda java\nDateTime.now().getMillis() / 1000\n\n// java >= 8\nInstant.now().getEpochSecond()' },
            { language: 'C', code: '#include <sys/time.h>\n\n// ...\nstruct timeval tv;\ngettimeofday(&tv, NULL);\n// Seconds: tv.tv_sec\n// Milliseconds: tv.tv_sec * 1000LL + tv.tv_usec / 1000' },
            { language: 'JavaScript', code: 'Math.floor(Date.now() / 1000)' },
            { language: 'Python', code: 'import time\nint(time.time())' },
            { language: 'Dart', code: 'import "dart:io";\nvoid main() {\n  print(DateTime.now().millisecondsSinceEpoch / 1000);\n}' },
            { language: 'Rust', code: 'use std::time::{SystemTime, UNIX_EPOCH};\nfn main() {\n  let start = SystemTime::now();\n  let since_the_epoch = start.duration_since(UNIX_EPOCH)\n    .expect("Time went backwards");\n  println!("{}", since_the_epoch.as_secs());\n}' },
            { language: 'Kotlin', code: 'val currentTime = System.currentTimeMillis() / 1000\nprintln(currentTime)' },
            { language: 'C#', code: 'long currentTime = DateTimeOffset.UtcNow.ToUnixTimeSeconds();\nConsole.WriteLine(currentTime);' },
            { language: 'TypeScript', code: 'console.log(Math.floor(Date.now() / 1000));' },
            { language: 'C++', code: '#include <iostream>\n#include <chrono>\nint main() {\n  auto now = std::chrono::system_clock::now();\n  auto seconds = std::chrono::duration_cast<std::chrono::seconds>(now.time_since_epoch()).count();\n  std::cout << seconds << std::endl;\n  return 0;\n}' }
        ]);

        const timezones = ref([
            { label: 'Africa/Cairo', value: 'Africa/Cairo' },
            { label: 'Africa/Johannesburg', value: 'Africa/Johannesburg' },
            { label: 'America/Anchorage', value: 'America/Anchorage' },
            { label: 'America/Caracas', value: 'America/Caracas' },
            { label: 'America/Chicago', value: 'America/Chicago' },
            { label: 'America/Denver', value: 'America/Denver' },
            { label: 'America/Honolulu', value: 'America/Honolulu' },
            { label: 'America/Los_Angeles', value: 'America/Los_Angeles' },
            { label: 'America/Mexico_City', value: 'America/Mexico_City' },
            { label: 'America/New_York', value: 'America/New_York' },
            { label: 'America/Phoenix', value: 'America/Phoenix' },
            { label: 'America/Sao_Paulo', value: 'America/Sao_Paulo' },
            { label: 'America/Toronto', value: 'America/Toronto' },
            { label: 'America/Vancouver', value: 'America/Vancouver' },
            { label: 'Asia/Bangkok', value: 'Asia/Bangkok' },
            { label: 'Asia/Hong_Kong', value: 'Asia/Hong_Kong' },
            { label: 'Asia/Kolkata', value: 'Asia/Kolkata' },
            { label: 'Asia/Seoul', value: 'Asia/Seoul' },
            { label: 'Asia/Shanghai', value: 'Asia/Shanghai' },
            { label: 'Asia/Singapore', value: 'Asia/Singapore' },
            { label: 'Asia/Tokyo', value: 'Asia/Tokyo' },
            { label: 'Australia/Sydney', value: 'Australia/Sydney' },
            { label: 'Europe/Berlin', value: 'Europe/Berlin' },
            { label: 'Europe/London', value: 'Europe/London' },
            { label: 'Europe/Madrid', value: 'Europe/Madrid' },
            { label: 'Europe/Moscow', value: 'Europe/Moscow' },
            { label: 'Europe/Paris', value: 'Europe/Paris' },
            { label: 'Europe/Rome', value: 'Europe/Rome' },
            { label: 'Pacific/Auckland', value: 'Pacific/Auckland' },
            { label: 'Pacific/Fiji', value: 'Pacific/Fiji' },
            { label: 'UTC', value: 'UTC' },
        ]);

        const copyCode = (code) => {
            const el = document.createElement('textarea');
            el.value = code;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            // alert('Code copied to clipboard');
        };
        const handleSelect = (key, keyPath) => {
            console.log(key, keyPath)
            // this.$router.push(keyPath);
            // this.$router.push(keyPath);
            router.push(key);
        }
        const handleOpen = (key, keyPath) => {
            console.log(key, keyPath)
        }
        const handleClose = (key, keyPath) => {
            console.log(key, keyPath)
        }
        return {
            activeIndex,
            currentTimestamp,
            form,
            timestampUnit,
            timezone,
            timezones,
            convertTimestamp,
            convertDatetime,
            codeSnippets,
            visibleCopyButtons,
            showCopyButton,
            hideCopyButton,
            copyCode,
            handleSelect,
            handleOpen,
            handleClose,
        };
    }
};
</script>

<style>
.el-main {
    padding: 20px;
}

.code-container {
    position: relative;
}

.copy-button {
    position: absolute;
    top: 0;
    right: 0;
}

.el-aside {
    width: 200px;
    position: fixed;
    top: 0;
    bottom: 0;
}

</style>
