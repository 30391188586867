// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'; // 这是正确的路径
import MenuView from './components/MenuView.vue';
import  FooterView  from "./components/FooterView.vue";
import { createHead } from '@vueuse/head';
// 按需引入 Element Plus 组件
// import {
//     ElContainer,
//     ElHeader,
//     ElMenu,
//     ElMenuItem,
//     ElMain,
//     ElFooter,
//     ElRow,
//     ElCol,
//     ElSubMenu,
//     ElForm,
//     ElFormItem,
//     ElInput,
//     ElRadio,
//     ElButton,
//     ElAlert,
//     ElAside,
//     ElUpload,
//     ElIcon,
//     ElTable,
//     ElRadioGroup,
//     ElDivider,
//     ElOption,
//     ElSelect,
//     ElCard,
//     ElMessage
//   } from 'element-plus';
  
  // import 'element-plus/es/components/container/style/css';
  // import 'element-plus/es/components/header/style/css';
  // import 'element-plus/es/components/menu/style/css';
  // import 'element-plus/es/components/menu-item/style/css';
  // import 'element-plus/es/components/main/style/css';
  // import 'element-plus/es/components/footer/style/css';
  // import 'element-plus/es/components/row/style/css';
  // import 'element-plus/es/components/col/style/css';
  // import 'element-plus/es/components/sub-menu/style/css';
  // import 'element-plus/es/components/form/style/css';
  // import 'element-plus/es/components/form-item/style/css';
  // import 'element-plus/es/components/input/style/css';
  // import 'element-plus/es/components/radio/style/css';
  // import 'element-plus/es/components/button/style/css';
  // import 'element-plus/es/components/alert/style/css';
  // import 'element-plus/es/components/aside/style/css';
  // import 'element-plus/es/components/upload/style/css';
  // import 'element-plus/es/components/icon/style/css';
  // import 'element-plus/es/components/table/style/css';
  // import 'element-plus/es/components/radio-group/style/css';
  // import 'element-plus/es/components/divider/style/css';
  // import 'element-plus/es/components/option/style/css';
  // import 'element-plus/es/components/select/style/css';
  // import 'element-plus/es/components/card/style/css';
  // import 'element-plus/es/components/message/style/css';
  

const app = createApp(App);
const head = createHead();

app.use(router);
app.use(head)
app.use(ElementPlus);
// 注册 Element Plus 组件
// app.component(ElContainer.name, ElContainer);
// app.component(ElHeader.name, ElHeader);
// app.component(ElMenu.name, ElMenu);
// app.component(ElMenuItem.name, ElMenuItem);
// app.component(ElMain.name, ElMain);
// app.component(ElFooter.name, ElFooter);
// app.component(ElRow.name, ElRow);
// app.component(ElCol.name, ElCol);
// app.component(ElSubMenu.name, ElSubMenu);
// app.component(ElForm.name, ElForm);
// app.component(ElFormItem.name, ElFormItem);
// app.component(ElInput.name, ElInput);
// app.component(ElRadio.name, ElRadio);
// app.component(ElButton.name, ElButton);
// app.component(ElAlert.name, ElAlert);
// app.component(ElAside.name, ElAside);
// app.component(ElUpload.name, ElUpload);
// app.component(ElIcon.name, ElIcon);
// app.component(ElTable.name, ElTable);
// app.component(ElRadioGroup.name, ElRadioGroup);
// app.component(ElDivider.name, ElDivider);
// app.component(ElOption.name, ElOption);
// app.component(ElSelect.name, ElSelect);
// app.component(ElCard.name,ElCard);
// app.component(ElMessage.name,ElMessage);

app.component('MenuView', MenuView);
app.component('FooterView',FooterView);
app.mount('#app');
